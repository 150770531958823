<template>
  <div class="table-cell-content">
    <div
      class="truncate-container"
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
      @focus="showTooltip"
      @blur="hideTooltip"
      :id="`reference-${_uid}`"
    >
      <span>{{ truncatedText }}</span>
      <div
        v-show="shouldShowTooltip && isHovering"
        class="floating-tooltip"
        :id="`tooltip-${_uid}`"
      >
        <div class="popover" :class="`bs-popover-${placement}`">
          <h3 class="popover-header">{{ title }}</h3>
          <div class="popover-body">{{ content }}</div>
          <div class="arrow" :id="`arrow-${_uid}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { arrow, autoPlacement, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { debounce } from 'lodash';

export default {
  name: 'TableCell',
  props: {
    content: {
      type: [String, Number],
      required: true,
      validator: value => value != null
    },
    title: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 15,
      validator: value => value > 0
    },
  },
  data() {
    return {
      isHovering: false,
      placement: 'top',
    };
  },
  computed: {
    truncatedText() {
      const text = String(this.content);
      return text.length > this.maxLength
        ? text.slice(0, this.maxLength) + '...'
        : text;
    },
    shouldShowTooltip() {
      return String(this.content).length > this.maxLength;
    },
  },
  methods: {
    updatePosition: debounce(async function() {
      const referenceEl = document.getElementById(`reference-${this._uid}`);
      const tooltipEl = document.getElementById(`tooltip-${this._uid}`);
      const arrowEl = document.getElementById(`arrow-${this._uid}`);

      if (!referenceEl || !tooltipEl || !arrowEl) return;

      computePosition(referenceEl, tooltipEl, {
        placement: 'top',
        middleware: [
          offset(6),
          flip(),
          shift(),
          autoPlacement({
            alignment: 'start',
            allowedPlacements: ['top', 'bottom'],
          }),
          arrow({ element: arrowEl, padding: 8 })],
        strategy: 'fixed',
      }).then(({ x, y, middlewareData, placement }) => {
        Object.assign(tooltipEl.style, { left: `${x}px`, top: `${y}px` })
        this.placement = placement

        const { x: arrowX, y: arrowY } = middlewareData.arrow
        Object.assign(arrowEl.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
        })
      })
    }, 16),
    showTooltip() {
      this.isHovering = true;
      this.$nextTick(this.updatePosition);
    },
    hideTooltip() {
      this.isHovering = false;
    },
  },
};
</script>

<style scoped>
.table-cell-content {
  position: relative;
}

.truncate-container {
  display: inline-block;
}

.floating-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: max-content;
  padding: 5px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 90%;
  z-index: 10000;
  pointer-events: none;
  transition: opacity 0.2s;

  .popover {
    position: relative;
  }
}
</style>
